import React from "react";
import { useForm } from "react-hook-form";
import styles from '../../../assets/styles/form.module.scss'

import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setData, validateAccount } from '../registrationSlice';

// Accountのバリデーションチェック
// 利用可能な場合はtrue, 利用不可の場合はfalseを返します。
// 処理は以下のファイルに書かれています。
// amplify/backend/function/AccountValidation/src/app.js（39行目あたりから）
// (async function () {
//   const response = await validateAccount('aaaaaa')
//   console.log(response);
// })();

const input_props = [
  {
    type: "subdomain",
    label_name: "希望URL", name: "url",
    placeholder: "例: farend",
    required: '必須項目です。',
    pattern: {
      value: /^[A-Za-z0-9][A-Za-z0-9-]+[A-Za-z0-9]$/,
      message: "半角英文字, 数字, ハイフン(-)が使用できます。ハイフン(-)で始まる、または終わるものは使用できません。"
    },
    minLength: {
      value: 3,
      message: "3文字以上です。"
    },
    maxLength: {
      value: 63,
      message: "63文字以内です。"
    },
    notes: "3文字以上で、半角英文字（小文字）, 数字, ハイフン(-)が使用できます。ハイフン(-)で始まる、または終わるものは使用できません。"
  },
  {
    type: "select_plan",
    name: "select_plan",
    label_name: "無料お試しで利用するプランの選択",
    required: '必須項目です。',
    list:[
      {label: "スタンダード", value: 'standard'},
      {label: "エンタープライズ ※", value: 'enterprise'},
    ],
    default_value: "standard",
    notes: "※SAML認証機能が必要な場合はエンタープライズを選択してください。"
  },
  {
    type: "user-name",
    group_title: "お申込担当者氏名",
    list: [
      {
        label_name: "姓", name: "last_name",
        placeholder: "例: 赤田",
        required: '必須項目です。'
      },
      {
        label_name: "名", name: "first_name",
        placeholder: "例: 舞",
        required: '必須項目です。'
      }
    ]
  },
  {
    type: "email",
    label_name: "メールアドレス", name: "email",
    // FIX: 以下、placeholder, required, pattern は使用していないことを確認してから削除する。
    placeholder: "例: sample@example.com",
    required: '必須項目です。',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "メールアドレスが正しくありません。"
    }
  },
  {
    type: "corporateIndivisual",
    name: "corporateIndivisual",
    label_name: "法人/個人",
    required: '必須項目です。',
  },
  {
    type: "organization",
    label_name: "会社・団体名", name: "organization",
    placeholder: "例: ファーエンドテクノロジー株式会社",
    required: '必須項目です。'
  },
  {
    type: "department",
    label_name: "部署名", name: "department",
    placeholder: ""
  },
  {
    type: "zip_code",
    label_name: "郵便番号", name: "zip_code",
    placeholder: "例: 690-0003",
    required: '必須項目です。',
    pattern: {
      value: /^\d{3}-?\d{4}$/,
      message: "郵便番号が正しくありません。"
    },
  },
  {
    label_name: "住所１（都道府県市町村）", name: "address1",
    placeholder: "例: 島根県松江市朝日町",
    required: '必須項目です。'
  },
  {
    label_name: "住所２（番地）", name: "address2",
    placeholder: "例: 498番地",
    required: '必須項目です。',
  },
  {
    label_name: "ビル・マンション名", name: "address3",
    placeholder: "例: 松江センタービル",
    notes: "郵便物を確実にお届けできるよう、ビル・マンション名は省略せずにご記入ください。サービスやお手続きの流れをご案内するスタートアップガイドとRedmine初心者向け冊子を無料でお送りします。"
  },
  {
    label_name: "電話番号", name: "tel",
    placeholder: "例: 0852210460 (半角でご記入ください)",
    required: '必須項目です。',
    pattern: {
      value: /^[0-9-]+$/,
      message: "半角でご記入ください。"
    },
    notes: "無料お試し中に営業のお電話がかかることはございません。"
  },
  {
    label_name: "業種",
    type: "select",
    name: "industry",
    required: '必須項目です。',
    list:[
      "農業・林業・漁業",
      "鉱業・採石業・砂利採取業",
      "建設業",
      "製造業",
      "電気・ガス・熱供給・水道業",
      "情報通信業",
      "運輸業・郵便業",
      "卸売業・小売業",
      "金融業・保険業",
      "不動産業・物品賃貸業",
      "学術研究・専門・技術サービス業",
      "宿泊業・飲食サービス業",
      "生活関連サービス業・娯楽業",
      "教育・学習支援業",
      "医療・福祉",
      "複合サービス事業",
      "その他サービス業",
      "公務・行政"
    ]
  },
  {
    label_name: "あなたの役割を教えてください",
    type: "select",
    name: "role",
    required: '必須項目です。',
    list:[
      "プロジェクト管理・事務局",
      "情報システム開発・運用",
      "企画・マーケティング",
      "営業・営業事務",
      "お客様サポート・サクセス",
      "バックオフィス（人事・経理・総務等）",
      "その他"
    ]
  },
  {
    label_name: "ご利用予定人数",
    type: "select",
    name: "numberOfPeople",
    required: '必須項目です。',
    list:[
      "〜10人",
      "11人〜50人",
      "51人〜100人",
      "101人〜300人",
      "301人〜1000人",
      "1001人以上"
    ]
  },
  {
    label_name: "Redmineのご利用経験を教えてください",
    type: "select",
    name: "exp",
    required: '必須項目です。',
    list:[
      "Redmineの利用経験あり",
      "Redmineの利用経験なし",
      "Redmineを使いはじめたばかり"
    ]
  },
  {
    label_name: "参考までに現在のご検討状況を教えてください",
    type: "select",
    name: "consideration",
    required: '必須項目です。',
    list:[
      "契約予定-低",
      "契約予定-中",
      "契約予定-高"
    ]
  },
  {
    type: "comparison",
    name: "comparison",
    label_name: '比較検討されるサービス名を教えて下さい',
    textarea: true,
  },
  {
    type: "purpose",
    name: "purpose",
    question: "ご利用の目的を教えて下さい(複数選択可)",
    list:[
      "プロジェクト管理全般",
      "タスク・課題管理",
      "スケジュール・進捗管理",
      "工数管理",
      "情報の見える化・共有",
      "業務標準・効率化"
    ]
  },
  {
    type: "source",
    name: "source",
    question: 'My Redmineをどうやってお知りになりましたか',
    list:[
      "Web検索",
      "Redmine.JP",
      "サービス比較サイト",
      "新聞・雑誌",
      "知人",
      "SNS",
      "Redmineを利用中"
    ]
  }
]

const InputField = React.forwardRef((props, ref) => {
  const {textarea, ...rest} = props
  return textarea ? <textarea ref={ref} {...rest} /> : <input ref={ref} {...rest} />
})

const Input = (props) => {
  const {item, register, def_data, errors} = props;
  return (
    <>
      <span className={styles.input_label}><label htmlFor={item.name}>{item.label_name}</label>{ item.required && <span className={styles.required_tag}>必須</span> }</span>
      <span className={styles.input_value}>
        <InputField
          textarea={item.textarea}
          name={item.name}
          ref={register(
          {
            required: item.required,
            ...(item.pattern ? { pattern: {
                value: new RegExp(item.pattern.value),
                message: item.pattern.message
            }} : null),
            ...(item.max ? { max: item.max } : null),
            ...(item.min ? { min: item.min } : null),
            ...(item.maxLength ? { maxLength: item.maxLength } : null),
            ...(item.minLength ? { minLength: item.minLength } : null),
            validate: value => /^\s+$/.test(value) ? '空白のみは入力できません。' : true,
          }
        )} defaultValue={ def_data[item.name] ? def_data[item.name]: ''} />
      </span>
      { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
      { item.placeholder && <span className={styles.notes_wrapper}>{item.placeholder}</span> }
      { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }
    </>
  )  
}

const Select = (props) => {
  const {item, register, def_data, errors} = props;
  return (
    <>
      { item.question && <div className={styles.input_title}>{item.question}</div> }
      { item.label_name && <span className={styles.input_label}><label htmlFor={item.name}>{item.label_name}</label>{ item.required && <span className={styles.required_tag}>必須</span> }</span> }
      <div className={styles.select_container}>
        <select name={item.name}
          ref={register({required: item.required})}
          defaultValue={def_data[item.name] ? def_data[item.name]: ''}
        >
          <option value='' disabled>選択してください</option>
          {item.list.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
        <div className={styles.select_arrow}></div>
      </div>
      { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
      { item.placeholder && <span className={styles.notes_wrapper}>{item.placeholder}</span> }
      { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }
    </>
  );
}

const CheckBoxes = (props) => {
  const { item, register, def_data, watchEl, errors} = props;
  return (
    <>
      { item.question && <div className={styles.input_title}>{item.question}</div> }
      <div className={styles.checkbox_container}>
        {
          item.list.map((sub_item, index) => (
            <div className={styles.checkbox_col} key={index}>
              <input id={`${item.name}_${index}`} name={item.name} type="checkbox" value={sub_item} ref={register} defaultChecked={def_data[item.name] && def_data[item.name].includes(sub_item)} />
              <label htmlFor={`${item.name}_${index}`}>{sub_item}</label>
            </div>
          ))
        }
      </div>
      <div className={`${styles.checkbox_col} ${styles.checkbox_col_other}`}>
        <input id={`${item.name}_other`} name={item.name} type="checkbox" value='その他' ref={register} defaultChecked={def_data[item.name] && def_data[item.name].includes('その他')} />
        <label htmlFor={`${item.name}_other`}>その他</label>
        <span className={styles.input_value}>
          <input
            disabled={watchEl === undefined || (watchEl && !watchEl.includes('その他'))}
            id={`${item.name}_other_value`}
            name={`${item.name}_other_value`}
            ref={register}
            defaultValue={ def_data[`${item.name}_other_value`] ? def_data[`${item.name}_other_value`]: ''} />
        </span>
      </div>
      { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
      { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }      
    </>
  )
}

const RadioButtons = (props) => {
  const { item, register, def_data, watchEl, errors} = props;
  return (
    <>
      { item.question && <div className={styles.input_title}>{item.question}</div> }
      <div className={styles.radio_container}>
        {
          item.list.map((sub_item, index) => (
            <div className={`${styles.radio_col} ${styles.radio}`} key={index}>
              <input id={`${item.name}_${index}`} name={item.name} type="radio" value={sub_item} ref={register} defaultChecked={def_data[item.name] === sub_item} />
              <label htmlFor={`${item.name}_${index}`}>{sub_item}</label>
            </div>
          ))
        }
      </div>
      <div className={`${styles.radio_col} ${styles.radio_col_other} ${styles.radio}`}>
        <input id={`${item.name}_other`} name={item.name} type="radio" value='その他' ref={register} defaultChecked={def_data[item.name] === 'その他'} />
        <label htmlFor={`${item.name}_other`}>その他</label>
        <span className={styles.input_value}>
          <input
            disabled={watchEl === undefined || watchEl !== 'その他'}
            id={`${item.name}_other_value`}
            name={`${item.name}_other_value`}
            ref={register}
            defaultValue={ def_data[`${item.name}_other_value`] ? def_data[`${item.name}_other_value`]: ''} />
        </span>
      </div>
      { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
      { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }      
    </>
  )
}
const SelectPlan = (props) => {
  const { item, register, def_data, errors} = props;
  return (
    <>
      { item.label_name && <span className={styles.input_label}><label htmlFor={item.name}>{item.label_name}</label>{ item.required && <span className={styles.required_tag}>必須</span> }</span> }
      <div className={styles.radio_container}>
        {
          item.list.map((sub_item, index) => (
            <div className={`${styles.radio_row} ${styles.radio}`} key={index}>
              <input
                id={`${item.name}_${index}`}
                name={item.name}
                type="radio"
                value={sub_item.value}
                ref={register({required: item.required})}
                defaultChecked={
                  def_data[item.name] === undefined
                    ? item.default_value === sub_item.value
                    : def_data[item.name] === sub_item.value
                }
              />
              <label htmlFor={`${item.name}_${index}`}>{sub_item.label}</label>
            </div>
          ))
        }
      </div>
      { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
      { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }
      <span className={styles.notes_wrapper}>
        <a
          href="https://hosting.redmine.jp/service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          各プランの詳細をみる
        </a>
      </span>
    </>
  )
}

const Form = (props) => {

  // 認証済みのメールアドレスを取得
  const auth_data = useSelector(state => state.authentication.data);
  const verified_email = auth_data.email

  const { register, handleSubmit, errors, formState, watch } = useForm();
  const { isSubmitting } = formState;
  const def_data = useSelector(state => state.registration.data);
  const isChecking = (useSelector(state => state.registration.validation_status)) === 'sending';
  const watchCorporateIndivisual = watch('corporateIndivisual', def_data.corporateIndivisual);
  const watchSource = watch('source', def_data.source);
  const watchPurpose = watch('purpose', def_data.purpose);
  const dispatch = useDispatch();
  const onSubmit = data => {
    const url = data.url
    const zip_code = data.zip_code
    const tel = data.tel
    // サブドメインは小文字で統一
    data.url = url.toLowerCase()
    // 郵便番号にハイフンがなければ挿入
    data.zip_code = zip_code.indexOf('-') !== -1 ? zip_code : `${zip_code.slice(0, 3)}-${zip_code.slice(3)}`
    // 電話番号にハイフンがあれば削除
    data.tel = tel.replace(/-/g, '')
    dispatch(setData(data));
    props.history.push("/registration/confirm");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>

        <div className={styles.explanation_wrapper}>
          <p>My Redmine の無料お試しへのお申し込みありがとうございます。下記ご登録後、ログイン方法等をご案内するメールをお送りします。</p>
        </div>

        <div className={styles.form_wrapper}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.registration}>

            <div className={styles.input_wrapper}>
            {
              input_props.map((item, index) => (

                <div className={`${styles.input_row} ${errors[item.name] ? styles.input_has_error : ''}`} key={index} >

                  {
                    (() => {
                      // console.log(item.type)
                      switch (item.type) {
                        case "subdomain":
                          return (
                            <>
                              <span className={styles.input_label}><label htmlFor={item.name}>{item.label_name}</label>{ item.required && <span className={styles.required_tag}>必須</span> }</span>
                              <div className={styles.input_col}>
                                <span className={styles.input_value}>
                                  <input name={item.name} ref={register(
                                    {
                                      required: item.required,
                                      ...(item.pattern ? { pattern: {
                                          value: new RegExp(item.pattern.value),
                                          message: item.pattern.message
                                      }} : null),
                                      ...(item.max ? { max: item.max } : null),
                                      ...(item.min ? { min: item.min } : null),
                                      ...(item.maxLength ? { maxLength: item.maxLength } : null),
                                      ...(item.minLength ? { minLength: item.minLength } : null),
                                      validate: async value => {
                                        const res = await dispatch(validateAccount(value))
                                        return res.payload.response || '入力された希望URLはご利用いただけません。別の希望URLを指定してください。'
                                      },
                                    }
                                  )} defaultValue={ def_data[item.name] ? def_data[item.name]: ''}
                                  autoFocus={true} />
                                </span>
                                <span className={styles.domain_wrap}>.cloud.redmine.jp</span>
                              </div>
                              { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
                              { item.placeholder && <span className={styles.notes_wrapper}>{item.placeholder}</span> }
                              { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }
                            </>
                          )
                        case "user-name":
                          return (
                            <>
                              <div className={styles.input_title}>お申込担当者氏名<span className={styles.required_tag}>必須</span></div>
                              <div className={styles.input_col_wrap}>

                              {
                                item.list.map((sub_item, index) => (
                                  <div className={errors[sub_item.name] ? styles.input_has_error : ''} key={index}>
                                    <div className={styles.input_col}>
                                      <span className={styles.input_label}><label htmlFor={sub_item.name}>{sub_item.label_name}</label></span>
                                      <span className={styles.input_value}>
                                        <input name={sub_item.name} ref={register(
                                          {
                                            required: sub_item.required,
                                            ...(sub_item.pattern ? { pattern: {
                                                value: new RegExp(sub_item.pattern.value),
                                                message: sub_item.pattern.message
                                            }} : null),
                                            ...(sub_item.max ? { max: sub_item.max } : null),
                                            ...(sub_item.min ? { min: sub_item.min } : null),
                                            ...(sub_item.maxLength ? { maxLength: sub_item.maxLength } : null),
                                            ...(sub_item.minLength ? { minLength: sub_item.minLength } : null),
                                            validate: value => /^\s+$/.test(value) ? '空白のみは入力できません。' : true,
                                          }
                                        )} defaultValue={ def_data[sub_item.name] ? def_data[sub_item.name]: ''} />
                                      </span>
                                    </div>
                                  { errors[sub_item.name] && <span className={styles.errors}>{ errors[sub_item.name].message }</span> }
                                  { sub_item.placeholder && <span className={styles.notes_wrapper}>{sub_item.placeholder}</span> }
                                  { sub_item.notes && <span className={styles.notes_wrapper}>{sub_item.notes}</span> }
                                  </div>
                                ))
                              }
                              </div>

                            </>
                          )
                        case "questionary":
                          return (
                            <>
                              <div className={styles.input_title}>My Redmineをどうやってお知りになりましたか</div>

                              {
                                item.list.map((sub_item, index) => (
                                  <div className={styles.input_col} key={index}>
                                    <input id={`${item.name}_${index}`} name={item.name} type="checkbox" value={sub_item} ref={register} defaultChecked={def_data[item.name] && def_data[item.name].includes(sub_item)} />
                                    <label htmlFor={`${item.name}_${index}`}>{sub_item}</label>
                                  </div>
                                ))
                              }
                              { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }

                            </>
                          )
                        case "purpose":
                          return (
                            <>
                              <CheckBoxes
                                item={item}
                                register={register}
                                def_data={def_data}
                                watchEl={watchPurpose}
                                errors={errors}
                              />
                            </>
                          )
                        case "source":
                          return (
                            <>
                              <RadioButtons
                                item={item}
                                register={register}
                                def_data={def_data}
                                watchEl={watchSource}
                                errors={errors}
                              />
                            </>
                          )
                        case "select_plan":
                          return (
                            <>
                              <SelectPlan item={item} register={register} def_data={def_data} watchEl={watchSource} errors={errors} />
                            </>
                          )
                        case "email":
                          return (
                            <>
                              <span className={styles.input_label}><label htmlFor={item.name}>{item.label_name}</label></span>
                              <span className={styles.value_wrap}>{ auth_data.email}</span>
                              <input type='hidden' name={item.name} defaultValue={verified_email} ref={register()} />
                            </>
                          )
                        case "zip_code":
                          return (
                            <>
                            <span className={styles.input_label}><label htmlFor={item.name}>{item.label_name}</label>{ item.required && <span className={styles.required_tag}>必須</span> }</span>
                            <span className={styles.input_value}>
                              <input name={item.name} ref={register(
                                {
                                  required: item.required,
                                  ...(item.pattern ? { pattern: {
                                      value: new RegExp(item.pattern.value),
                                      message: item.pattern.message
                                  }} : null),
                                  ...(item.max ? { max: item.max } : null),
                                  ...(item.min ? { min: item.min } : null),
                                  ...(item.maxLength ? { maxLength: item.maxLength } : null),
                                  ...(item.minLength ? { minLength: item.minLength } : null),
                                  validate: value => /^\s+$/.test(value) ? '空白のみは入力できません。' : true,
                                }
                              )} defaultValue={ def_data[item.name] ? def_data[item.name]: ''}
                              onKeyUp={e => {
                                const { AjaxZip3 } = window
                                AjaxZip3.zip2addr('zip_code', '', 'address1', 'address1', '', '', false)
                              }} />
                            </span>
                            { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
                            { item.placeholder && <span className={styles.notes_wrapper}>{item.placeholder}</span> }
                            { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }
                            </>
                          )
                        case "corporateIndivisual":
                          return (
                            <>
                              <span className={styles.input_label}><label htmlFor={item.name}>{item.label_name}</label>{ item.required && <span className={styles.required_tag}>必須</span> }</span>
                              {
                                <div className={styles.input_col}>
                                  <div className={styles.radio}>
                                    <input id="corporateIndivisual_corporate" name="corporateIndivisual" type="radio" value="法人" ref={register({ required: true })} defaultChecked={ watchCorporateIndivisual === undefined || def_data.corporateIndivisual === '法人'}/>
                                    <label htmlFor="corporateIndivisual_corporate">法人</label>
                                  </div>
                                  <div className={styles.radio}>
                                    <input id="corporateIndivisual_indivisual" name="corporateIndivisual" type="radio" value="個人" ref={register({ required: true })} defaultChecked={ def_data.corporateIndivisual === '個人'}/>
                                    <label htmlFor="corporateIndivisual_indivisual">個人</label>
                                  </div>
                                </div>
                              }
                              { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }
                            </>
                          )
                        case "organization":
                        case "department":
                          return (
                            <>
                              {(watchCorporateIndivisual === undefined || watchCorporateIndivisual === '法人') && (
                                <Input item={item} register={register} def_data={def_data} errors={errors} />
                              )}
                            </>
                          )
                        case "select":
                          return (
                            <>
                              <Select item={item} register={register} def_data={def_data} errors={errors} />
                            </>
                          )
                        default:
                          return (
                            <>
                              <Input item={item} register={register} def_data={def_data} errors={errors} />
                            </>
                          )
                      }
                    })()
                  }

                </div>

              ))
            }

            </div>

            <div className={styles.submit_btn_wrapper}>
              <button type="submit" className={styles.confirm_btn} disabled={isChecking || isSubmitting}>
                { isChecking ? <span>確認画面に進む<span className={styles.sending}></span></span> : <span>確認画面に進む</span> }
              </button>
            </div>

          </form>

          <div className={styles.policy_wrapper}>
            <p>お預かりした個人情報は、<a href='https://www.farend.co.jp/profile/privacy/' target='_blank' rel='noopener noreferrer'>ファーエンドテクノロジー株式会社 個人情報保護方針</a>に則って使用します。</p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default withRouter(Form);